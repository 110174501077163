import  React,{useState, useEffect} from "react"
import Testx from '../Components/test';
import * as queryString from "query-string";
import  secureLocalStorage  from  "react-secure-storage";

export default function Test(props) {
    console.log('sssss');
    console.clear();
    // const url_params = queryString.parse(props.location.search);
    const [inputText, setInputText] = useState('');
    const data = `lorem <b onmouseover="alert('mouseover');">ipsum</b>`;
    // useEffect(() => {
	//     secureLocalStorage.setItem("object", {
	// 	    message:  "This is testing of local storage",
	//     });
	//     setInputText(secureLocalStorage.getItem("object"));
	// }, []);
    return (
        <div>
            {/* <p dangerouslySetInnerHTML={{__html: data}}></p> */}
            <p>{data}</p>
            {/* <textarea onChange={(e)=>setInputText(e.target.value)}></textarea> */}
            {/* <div dangerouslySetInnerHTML={{__html: inputText}}></div> */}
            {/* <img onerror='alert("Hacked");' src='invalid-image' /> */}
            {/* <div>{inputText}</div> */}
            {/* {inputText.message} */}
        </div>
    )
}